import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';

import imgAdress1 from '../../assets/images/contact-us/address.svg';
import imgAdress2 from '../../assets/images/contact-us/Reception Hours_.svg';
import imgAdress3 from '../../assets/images/contact-us/phone-call.svg';
import imgAdress4 from '../../assets/images/contact-us/whatsapp-2.svg';
import imgAdress5 from '../../assets/images/contact-us/email-3.svg';

import '../../assets/styles/pages/contact-us-page.scss';

const Map = () => {
  return (
    <GoogleMap
      defaultOptions={{ styles: demoFancyMapStyles3 }}
      defaultZoom={17}
      defaultCenter={{ lat: 41.40014184539929, lng: 2.1747107783263977 }}
    >
      <Marker position={{ lat: 41.40014184539929, lng: 2.1747107783263977 }} />
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

const demoFancyMapStyles3 = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ca2031',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 51,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 30,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 40,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ffff00',
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];

const EMPTY_CONTACT_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
  comments: '',
};

const ContactUs = () => {
  const [formData, setFormData] = useState(EMPTY_CONTACT_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
        userFromGoOverseas: window?.userFromGoOverseas,
        userFromGoAbroad: window?.userFromGoAbroad,
      }),
    });

    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: 'Contact form', // Source
      MERGE25: formData.comments,
    };
    addToMailchimp(
      formData.userEmail,
      MailchimpData,
      'https://tefl-iberia.us9.list-manage.com/subscribe/post?u=14228e00bf6efc88dcde365c5&amp;id=b2b67afb12'
    );

    setFormData(EMPTY_CONTACT_DATA);

    navigate('/contact/thank-you');
  };

  return (
    <Layout title={{ id: 'contacto.seo.title' }} description={{ id: 'contacto.seo.description' }}>
      <div className="contact-us-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="contacto.sec1.title" />
            </h1>
            <p className="description">
              <FormattedMessage id="contacto.sec1.description" />
            </p>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <form onSubmit={handleSubmit} className="form-wrapper">
                  <h1 className="c-title-22 form__title">
                    <FormattedMessage id="contacto.sec2.form" />
                  </h1>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.firstName" />
                    </p>
                    <input
                      value={formData.firstName}
                      onChange={handleInputChange}
                      name="firstName"
                      className="input__first-name"
                      type="text"
                      placeholder="John"
                      required
                    />
                  </label>
                  <label className="form__last-name">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.lastName" />
                    </p>
                    <input
                      value={formData.lastName}
                      onChange={handleInputChange}
                      name="lastName"
                      className="input__last-name"
                      type="text"
                      placeholder="Smith"
                      required
                    />
                  </label>
                  <label className="form__email">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.email" />
                    </p>
                    <input
                      value={formData.userEmail}
                      onChange={handleInputChange}
                      name="userEmail"
                      className="input__email"
                      type="email"
                      placeholder="johnsmith@gmail.com"
                      required
                    />
                  </label>
                  <label className="form__textarea">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.comment" />
                    </p>
                    <textarea
                      value={formData.comments}
                      onChange={handleInputChange}
                      name="comments"
                      className="input__textarea"
                      id=""
                      placeholder="Write your message…"
                    />
                  </label>
                  <button type="submit" className="c-btn c-btn--red">
                    <FormattedMessage id="contacto.sec2.submit" />
                  </button>
                </form>
              </div>

              <div className="col-lg-6 col-md-5">
                <div className="right">
                  <p className="c-text-18 description second-section__l">
                    <FormattedMessage id="contacto.sec2.text" />{' '}
                  </p>
                  <div className="wrapper">
                    <div className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress1} alt="TEFL Iberia" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.address" />
                        </h3>
                        <p className="c-text-14">
                          Carrer d’Aragó 403,
                          <br />
                          Entresuelo 1º
                          <br />
                          Barcelona, 08013
                          <br />
                          Spain
                        </p>
                      </div>
                    </div>
                    {/* <div className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress1} alt="TEFL Iberia" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.address" />
                        </h3>
                        <p className="c-text-14">
                          TEFL Iberia <br /> Calle Ardilla 10 <br /> 41010, Seville, Spain
                        </p>
                      </div>
                    </div> */}

                    <div className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress2} alt="09.00 – 17.00, Monday to Friday" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.hours" />
                        </h3>
                        <p className="c-text-14">09.00 – 17.00, Monday to Friday</p>
                      </div>
                    </div>
                    <a href="tel:+34 932 494 676" className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress3} alt="34 932 494 676" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.phone" />
                        </h3>
                        <p className="c-text-14">+34 932 494 676</p>
                      </div>
                    </a>
                    <a href="tel:+34644022867" className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress4} alt="+34644022867" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.whatsapp" />
                        </h3>
                        <p className="c-text-14">(+34) 644 022 867</p>
                      </div>
                    </a>
                    <a href="mailto:coordination@tefl-iberia.com" className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress5} alt="coordination@tefl-iberia.com" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.email" />
                        </h3>
                        <p className="c-text-14">coordination@tefl-iberia.com</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section google-map-section">
          <WrappedMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfMKb2czX8NxQxofnL7b6tJs9soJ18apQ&callback=initMap&libraries=&v=weekly"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `449px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;
